import React from "react";
import '../css/Menu-Specials.css';

export default function MenuSpecials(){

    return(
        <div className="menu-specials-container">
            <div className="menu-specials-content-container">
                <h2></h2>
            </div>
            <h2 className="menu-specials-bottom-text">ASK OUR BARISTAS :)</h2>
        </div>
    )
}